import { Typography, Grid, Paper, Box } from "@mui/material"
import ArticleIcon from '@mui/icons-material/Article';
import LocalFireDepartmentIcon from '@mui/icons-material/LocalFireDepartment';
import StorageIcon from '@mui/icons-material/Storage';
import WhatshotIcon from '@mui/icons-material/Whatshot';


type AboutProps = {
    burned: string;
  };

export const About = (props: AboutProps) => {

    return (

        <Grid container
            spacing={{ xs: 4, md: 6, lg: 12 }}
            direction="row"
            alignItems="center"
            paddingTop={{ xs: 5, md: 8, lg: 12 }} paddingBottom={{ xs: 3, md: 5, lg: 8 }}
        >
            <Grid item xs={12} md={6}>
                <Paper sx={{ display: "flex", minHeight: "12rem", flexDirection: "row", alignItems: "center" }} elevation={4}>
                    <Box sx={{ flex: "1" }}><center><ArticleIcon sx={{ height: "80px", width: "80px" }} /></center></Box>
                    <Box sx={{ flex: "2", flexDirection: 'column' }}>
                        <Typography variant='h4' fontFamily="go3v2">Tax</Typography>
                        <Typography paddingTop={3} variant='h6' fontWeight="bold">"No Buy or Sell tax" </Typography>
                    </Box>
                </Paper>
            </Grid>
            <Grid item xs={12} md={6}>
                <Paper sx={{ display: "flex", minHeight: "12rem", flexDirection: "row", alignItems: "center" }} elevation={4}>
                    <Box sx={{ flex: "1" }}><center> <LocalFireDepartmentIcon sx={{ height: "80px", width: "80px" }} /></center></Box>
                    <Box sx={{ flex: "2", flexDirection: 'column' }}>
                        <Typography variant='h4' fontFamily="go3v2">Burnt LP</Typography>
                        <Typography paddingTop={3} variant='h6' fontWeight="bold">Liquidity burned</Typography>
                    </Box>
                </Paper>
            </Grid>
            <Grid item xs={12} md={6}>
                <Paper sx={{ display: "flex", minHeight: "12rem", flexDirection: "row", alignItems: "center" }} elevation={4}>
                    <Box sx={{ flex: "1" }}><center><StorageIcon sx={{ height: "80px", width: "80px" }} /></center></Box>
                    <Box sx={{ flex: "2", flexDirection: 'column' }}>
                        <Typography variant='h4' fontFamily="go3v2">Original Total Supply</Typography>
                        <Typography paddingTop={3} variant='h6' fontWeight="bold">100,000,000 tokens</Typography>
                    </Box>
                </Paper>
            </Grid>
            <Grid item xs={12} md={6}>
                <Paper sx={{ display: "flex", minHeight: "12rem", flexDirection: "row", alignItems: "center" }} elevation={4}>
                    <Box sx={{ flex: "1" }}><center><WhatshotIcon sx={{ height: "80px", width: "80px" }} /></center></Box>
                    <Box sx={{ flex: "2", flexDirection: 'column' }}>
                        <Typography variant='h4' fontFamily="go3v2">Supply Burns</Typography>
                        <Typography paddingTop={3} variant='h6' fontWeight="bold">{props.burned && props.burned}% of supply, burned</Typography>
                    </Box>
                </Paper>
            </Grid>
        </Grid>



    )
}