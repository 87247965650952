import './clouds.scss'

export const CloudBg = (props: {link:string}) => {
    return (
        <div className="cloudWrapper">
            <div className="x1">
                <a href={props.link}><div className="cloud"></div></a>
            </div>
            <div className="x2">
            <a href={props.link}><div className="cloud1"></div></a>
            </div>
            <div className="x3">
            <a href={props.link}><div className="cloud0"></div></a>
            </div>
            <div className="x4">
            <a href={props.link}><div className="cloud"></div></a>
            </div>
            <div className="x5">
            <a href={props.link}><div className="cloud1"></div></a>
            </div>
        </div>
    )
}

