import { Helmet } from 'react-helmet';
import LepeNoBg from './imgs/lepeNoBg.png';
import './App.css'
import './LepeDragon.scss';
import { AppBar, Box, Button, Container, Grid, Paper, Toolbar, Typography } from '@mui/material';
import XIcon from '@mui/icons-material/X';
import TelegramIcon from '@mui/icons-material/Telegram';
import { BasicTimeline } from './components/basicTimeline/basicTimeline';
import { CloudBg } from './components/cloudBg/cloudBg';
import DiamondIcon from '@mui/icons-material/Diamond';
import { useEffect, useState } from 'react';
import { About } from './components/about/about';
import { getTotalBurned } from './services/burnCounter';

const info = {
  contract: "9B31sgN9D1j1n53rkWF523Nkp4oBaktonUX7rUuyDRaP",
  contractScan: "https://solscan.io/token/9B31sgN9D1j1n53rkWF523Nkp4oBaktonUX7rUuyDRaP",
  buy: "https://raydium.io/swap/?inputCurrency=sol&outputCurrency=9B31sgN9D1j1n53rkWF523Nkp4oBaktonUX7rUuyDRaP&fixed=in",
  twitter: "https://twitter.com/Loongpepesolana",
  telegram: "https://t.me/loongpepesolana",
  description: ["The first Pepe fused with a Dragon, on a mystical journey to celebrate the Lunar New Year in the Solana realm.",
    "When the original dev sold and ran, discouraged holders united in the spirit of dragons!",
  ],
  descriptionHeader: "About $LEPE",
  header: "Welcome to LEPE"
}


const timelineInfo = [
  "Build the community and socials",
  "Reach 1000 holders",
  "CoinMarketCap & CoinGecko listings",
  "Burn half of supply"
]

const headerBullets: string[] = [
  "The only token burning DRAGON on Solana",
  "100% community-driven",
  "No pre-seed, no pre-launch",
  "Liquidity BURNED"
]

const LinkBar = () => {
  return (
    <Box>
      <Container>
        <Grid container
          direction="row"
          spacing={{ xs: 1, md: 3 }}
          alignItems="center"
          justifyContent="space-evenly"
          flex="1 1 auto"
        >
          <Grid item>
            <Button variant='contained' size='medium' href={info.contractScan}><Typography fontFamily="go3v2">Contract</Typography></Button>
          </Grid>
          <Grid item>
            <Button variant='contained' size='medium' href={info.buy}><Typography fontFamily="go3v2">Buy on SOL</Typography></Button>
          </Grid>
          <Grid item>
            <Button variant='contained' size='medium' href={info.telegram}><TelegramIcon /></Button>
          </Grid>
          <Grid item >
            <Button variant='contained' size='medium' href={info.twitter}><XIcon /></Button>
          </Grid>
        </Grid>
      </Container>
    </Box>
  )
}

function App() {
  const [burnedAmount, setBurnedAmount] = useState("");
  const [burnedAsPercentage, setBurnedAsPercentage] = useState("")

    useEffect(() => {
      async function fetchAmount() {
        let amount = await getTotalBurned();
        let amountAsPercentage = (amount/100000000*100).toFixed(2);
        setBurnedAmount(amount.toLocaleString());
        setBurnedAsPercentage(amountAsPercentage.toString())
      }
      fetchAmount()
    }, [])

  return (
    <Box>
      <Helmet>
        <title>LEPE, THE TOKEN BURNING DRAGON</title>
        <link rel="icon" type="image/png" href={LepeNoBg} sizes="16x16" />
      </Helmet>
      <AppBar position='static'>
        <Toolbar>
          <Typography fontFamily="go3v2" variant='h5'>LEPE THE TOKEN BURNING DRAGON</Typography>
        </Toolbar>
      </AppBar>

      <CloudBg link={info.buy} />
      <Container maxWidth="lg">

        <Box paddingTop={{ sm: 0, md: 6, lg: 12 }} paddingBottom={{ sm: 0, md: 6, lg: 12 }}>
          <Grid container
            spacing={{ xs: 0, md: 6 }}
            direction="row"
            alignItems={"center"}
          >
            <Grid item xs={12} md={7}>
              <Box display="flex" flexDirection="column" alignContent="center" justifyContent="space-between">
                <Typography paddingTop={4} paddingBottom={{ xs: 2, md: 5 }} align="center" variant='h3' fontFamily="go3v2" sx={{ textShadow: "#FFFF 0 0 10px" }} className=''>{info.header}</Typography>
                <Box alignSelf="center" paddingX={3}>
                  {headerBullets.map((item, index) => {
                    return <Typography key={"header" + index} display="flex" paddingTop={1} justifyContent="left" variant='h6' fontWeight="bold" sx={{ textShadow: "#FFFF 0 0 10px" }}><DiamondIcon fontSize="large" sx={{ paddingRight: 1, color: "" }} />{item}</Typography>
                  })}
                </Box>
                <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center">
                <Typography paddingY={2} fontFamily="go3v2" variant='h3' fontWeight="bold" sx={{ textShadow: "#FFFF 0 0 10px" }}>{burnedAmount}</Typography> 
                <Typography fontFamily="go3v2" variant='h4' sx={{ textShadow: "#FFFF 0 0 10px" }}>TOKENS</Typography>
                <Typography fontFamily="go3v2" variant='h1' className="vibrate-1 fire-text">BURNED</Typography>
                </Box>
                <Box paddingTop={6} paddingBottom={6} display={{ xs: 'none', md: "block" }}>
                  <LinkBar />
                </Box>
              </Box>

            </Grid>
            <Grid item xs={12} md={5} className='lepeDragon' display="flex" justifyContent="center">
              <img className="animate fire-text" src={LepeNoBg} alt="Long Pepe Transparnt Background"></img>
            </Grid>
          </Grid>
        </Box>

      </Container>

      <Box display={{ xs: "block", md: "none" }} paddingTop={3} paddingBottom={3} borderTop={1} bgcolor={"white"}>
        <LinkBar />
      </Box>

      <Box paddingTop={{ xs: 3, md: 8, lg: 12 }} paddingBottom={{ xs: 3, md: 5, lg: 8 }} borderTop={1} className="backgroundGreen">

        <Container>
          <center><Typography variant="h3" fontFamily="go3v2">{info.descriptionHeader}</Typography></center>
          <center>{info.description.map((item, index) => {
            return <Typography key={"header" + index} paddingTop={3} variant='h6' fontWeight="bold">{item}</Typography>
          })}</center>

          <About burned={burnedAsPercentage}/>

        </Container>

        <Box paddingTop={8}>
          <Container maxWidth="sm">
            <Paper elevation={4}>
              <Box padding={2} display="flex" flexDirection="column" alignItems="center">
                <Typography fontFamily="go3v2" variant='h3'>Roadmap</Typography>
                <BasicTimeline items={timelineInfo} />
              </Box>
            </Paper>
          </Container>
        </Box>
      </Box>


      <Box display={{ xs: "block" }} paddingTop={2} paddingBottom={2} borderTop={1} bgcolor={"white"}>
        <LinkBar />
      </Box>
    </Box >
  );
}

export default App;
