// Import the Solana web3.js library
const solanaWeb3 = require('@solana/web3.js');
const { Token, TOKEN_PROGRAM_ID } = require("@solana/spl-token");

// Create a new connection to the Solana blockchain
const connection = new solanaWeb3.Connection("https://distinguished-methodical-breeze.solana-mainnet.quiknode.pro/2ae155d9ecbcbe2df51595908e070a29a4dbf084/")

// Specify the public key of the wallet you want to check

const mintAddress = new solanaWeb3.PublicKey('9B31sgN9D1j1n53rkWF523Nkp4oBaktonUX7rUuyDRaP');


// Get all token accounts for the wallet
const originalTotalSupply = 100000000;

export const getCurrentTotalSupply = async () => {
  try {
    const mintInfo = await connection.getParsedAccountInfo(mintAddress);
    if (mintInfo.value) {
      const mintData = mintInfo.value.data;
      const rawSupply = mintData.parsed.info.supply;
      const decimals = mintData.parsed.info.decimals;

      const uiAmount = rawSupply / Math.pow(10, decimals);
      console.log(uiAmount)
      return parseInt(uiAmount);
    } else {
      console.log("Mint address not found or invalid.");
      return 0;
    }
  } catch (error) {
    console.error("Error fetching total supply:", error);
    return 0;
  }
}

export const getTotalBurned = async () => {
    let currentTotalSupply = await getCurrentTotalSupply();
    if (currentTotalSupply) {
      return originalTotalSupply - currentTotalSupply;
    } else return 0
};