import { Timeline, TimelineConnector, TimelineContent, TimelineDot, TimelineItem, TimelineSeparator, timelineItemClasses } from "@mui/lab";
import DiamondIcon from '@mui/icons-material/Diamond';
import { Typography } from "@mui/material";
type timelineInfoProps = {
    items: string[],
  }

export const BasicTimeline = (props: timelineInfoProps) => {
    return (
      <Timeline
        sx={{
          [`& .${timelineItemClasses.root}:before`]: {
            flex: 0,
            padding: 0,
          },
        }}
      >
        {props.items.map((item, index) => {
          if (index < props.items.length-1) {
            return <TimelineItem key={"timeline" + index}>
              <TimelineSeparator>
              <TimelineDot sx={{backgroundColor: "black"}}>
                  <DiamondIcon />
                </TimelineDot>
                <TimelineConnector />
              </TimelineSeparator>
              <TimelineContent sx={{ py: '12px', px: 2 }}>
                <Typography variant="h6" fontWeight="bold">{item}</Typography>
              </TimelineContent>
            </TimelineItem>
          }
          else {
            return <TimelineItem key={"timeline" + index}>
              <TimelineSeparator>
                <TimelineDot sx={{backgroundColor: "black"}}>
                  <DiamondIcon />
                </TimelineDot>
              </TimelineSeparator>
              <TimelineContent sx={{ py: '12px', px: 2 }}>
                <Typography variant="h6" fontWeight="bold">{item}</Typography>
              </TimelineContent>
            </TimelineItem>
          }
        }
        )}
      </Timeline>
    );
  }